//UAT

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    prod: false,
    basename: '',
    defaultPath: '/',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light',
    idleTimeout: 1200000, // 20 minutes in milliseconds
    presetColor: 'evrima', // default, theme1, theme2, theme3, theme4, theme5, theme6, evrima
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    ga: {
        id: 'G-FG1F164R2N'
    },
    aws: {
        baseUrl : 'https://2giah1qumk.execute-api.ap-southeast-2.amazonaws.com/Prod',
        xApiKey : 'yvlYIHG8FUH0hGmcSm763KK8JPhivDg44lpFpOM6',
        signalr : 'https://2giah1qumk.execute-api.ap-southeast-2.amazonaws.com/Prod/messagehub'
    },
    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
    awsAuth : {
        baseUrl : 'https://9r993n26fd.execute-api.ap-southeast-2.amazonaws.com/Prod',
        jwksAddressTemplate : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_QoScNvOW0/.well-known/jwks.json',
        jwksAddress : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_QoScNvOW0/.well-known/jwks.json',
        jwks : {"keys":[{"alg":"RS256","e":"AQAB","kid":"xXzL+G8GCSqsDFeixS9qOc3KCSPNih04V9IgmCy3tGs=","kty":"RSA","n":"02eVac1dfGPThS_2b5GPR-A5A-a3whG7yD-StAuHsWJ_USchEgKE2RIts-24A068-E4cUwnb79wJZd1zRlK7vzzFwfPKy9zKVirUz4-v2aij69jXDLGS_WjJ9qIxKQ4XKDW2lINsLYwFu2kxJkGChG0i3sgdofqZDKA_6ENIMTAm0lmaKsuFWzKHaTr83-JyXYC2EhoNBnzI0HM6TOVBToTRM5zEESTKlOECoxPuFYHSRia3HtQo8m5Bq5coA1jrwwuBWjU0uJ1flmuIWIHVGD9QAXPJi72FTyHA_4nnBV3FgP1K8PV4zJH50o-kUD5dlsBqN7dJBGTrTm6gPbWEzQ","use":"sig"},{"alg":"RS256","e":"AQAB","kid":"oBHxPwqDT+f9LpReejhhHY9I6czFiDaDEOcEQ+6d58I=","kty":"RSA","n":"tw5mdDa8bZMp9eXl72vhCIp8Jl9Z5CzJdW38jfT6YmyMyZQ99BZQSP6-01rke1x0EgpS6pACo-IxyjJFnEbV3RVVNm6vJVeZ6ybFUonVx0Y_MunfbPn37z3n-k93p_Hdjrl5TEBLOrqY4huzGBI_5Tckiz0iVEP791Xtxzj-V8qeSrpEaB3ia-im2HYWXWB23ycbQiTpXshEUXnwzUR6ZvoaftJfkWzbX7T7I5fZ7W7na99M80MW9lDx9_YFE0dhtTZmDJGI2r_eRZBYjq2Hh5HTfEuAioaGN_OwX8D2bowc7Bp802bzOvx28uQF8LZURGZGK7hEjCVVm1A5kxtnFQ","use":"sig"}]},
        aud : '57q7npl78s1afpetal44kj832u', // should match the app client ID that was created in the Amazon Cognito user pool
        iss : 'https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_QoScNvOW0',
        apiKeyPrefix: 'ApiKey',
        userPool: 'ap-southeast-2_QoScNvOW0',
        idPool: 'ap-southeast-2:50201263-b17f-4414-9ee8-53aaeefb530d',
        domain: 'evrimaconnect-uat.auth.ap-southeast-2.amazoncognito.com',
        redirectSignIn: 'https://connectuat.evrima.com.au',
        redirectSignOut: 'https://connectuat.evrima.com.au',
    },
    mui: {
        xGridKey: '2455a9e75aabf82c7acde90550ee5da2Tz05Nzg5NixFPTE3NTc2MzMwNzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
    },
    intercom : {
        baseUrl: 'https://api-iam.au.intercom.io',
        appId: 'bwt6qo4l',
        enabled: false
    },
    permissions: {
        transparent: true // if false, a badge saying permission required will show where components are blocked by incorrect permissions
    },
    help: {
        link: 'https://evrima.com/contact-us/?enquiry_type=Evrima%20Connect%20Support'
    },
    docApi: 'https://2giah1qumk.execute-api.ap-southeast-2.amazonaws.com/Prod/agreements'
};

export default config;
